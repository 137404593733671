import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Dropdown, DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-sort-by',
  standalone: true,
  imports: [DropdownModule, FormsModule, CommonModule],
  templateUrl: './sort-by.component.html',
  styleUrl: './sort-by.component.scss',
})
export class SortByComponent {
  selectedSortOption: any;
  @Input() sortOptions: any[] = [];
  @Input() placeholder: string = 'Select';
  @Input() labelName: string = 'name';
  @Input() dropdownClass!: string;
  @Input() selectedId: any;
  @Input() isFilter: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isShowClear: boolean = false;
  @ViewChild('dropdown') dropdown!: Dropdown;
  @Output() onSortChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<void> = new EventEmitter<void>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sortOptions'] || changes['selectedId']) {
      this.updateSelectedSortOption();
    }
  }

  updateSelectedSortOption(): void {
    if (this.selectedId === null) {
      this.selectedSortOption = null;
    } else if (this.selectedId && this.sortOptions) {
      this.selectedSortOption = this.sortOptions.find(
        (option: any) => option.id == this.selectedId
      );
    }
    this.cdRef.detectChanges();
  }

  onSortOptionChange(event: any) {
    if (this.selectedSortOption === null) {
      this.onClear.emit();
    } else {
      this.onSortChange.emit(this.selectedSortOption);
    }
  }

  resetFilter() {
    if (this.dropdown) {
      this.dropdown.filterValue = '';
      this.dropdown.resetFilter();
    }
  }
}
