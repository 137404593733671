import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageServiceService } from './local-storage-service.service';
import { set } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private chartHeadingSource = new BehaviorSubject<string>('');
  private zoomSubject = new BehaviorSubject<boolean>(false);
  private dateSubject = new BehaviorSubject<string>('');
  private startDateSubject = new BehaviorSubject<Date | null>(null);
  private endDateSubject = new BehaviorSubject<Date | null>(null);
  public isMobileSubject = new BehaviorSubject<boolean>(false);

  currentChartHeading = this.chartHeadingSource.asObservable();
  zoomState = this.zoomSubject.asObservable();
  currentDate = this.dateSubject.asObservable();
  currentStartDate = this.startDateSubject.asObservable();
  currentEndDate = this.endDateSubject.asObservable();

  constructor(
    private datePipe: DatePipe,
    private localStorageService: LocalStorageServiceService
  ) {}

  changeChartHeading(heading: string) {
    this.chartHeadingSource.next(heading);
  }

  toggleZoomState() {
    const currentState = this.zoomSubject.getValue();
    this.zoomSubject.next(!currentState);
  }

  setDate(date: any) {
    this.dateSubject.next(date);
  }

  getDate(): string {
    return this.dateSubject.getValue();
  }

  setStartDate(date: Date | null) {
    this.startDateSubject.next(date);
  }

  getStartDate(): Date | null {
    return this.startDateSubject.getValue();
  }

  setEndDate(date: Date | null) {
    this.endDateSubject.next(date);
  }

  getEndDate(): Date | null {
    return this.endDateSubject.getValue();
  }

  getFormErrors(
    form: FormGroup,
    fieldName: string,
    formControl: string,
    maxCharacter?: number,
    minCharacter?: number
  ): string | null {
    const control = form.get(formControl);
    if (control?.hasError('required')) {
      return `${fieldName} is required`;
    } else if (control?.hasError('whitespace')) {
      return `${fieldName} cannot be empty`;
    } else if (control?.hasError('alphanumeric')) {
      return `${fieldName} must be alphanumeric`;
    } else if (control?.hasError('maxLength')) {
      return `${fieldName} must be up to ${maxCharacter} characters`;
    } else if (control?.hasError('minlength')) {
      return `${fieldName} must be at least ${minCharacter} characters`;
    }
    return null;
  }

  formatDate(date: Date | null): string | null | boolean {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null;
  }

  alphanumericWithSpacesValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const errors: ValidationErrors = {};
      if (!/^[a-zA-Z0-9 ]*$/.test(value)) {
        errors['alphanumeric'] = true;
      }
      if (value.length > maxLength) {
        errors['maxLength'] = true;
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  notOnlyWhitespace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  formatDateWithTimezone(date: Date) {
    if (!date) {
      return '';
    }
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date.getTime() - timezoneOffset);
    return localISOTime;
  }

  formatDateTime(date: any) {
    if (date && moment(new Date(date)).isValid())
      return moment(date).format('MMM DD YYYY, h:mm A');
    else return '';
  }

  formatDateTimeV1(date: any) {
    if (date && moment(new Date(date)).isValid())
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    else return '';
  }

  generateYears(startYear: number): { id: number; name: number }[] {
    const currentYear = new Date().getFullYear() + 1;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push({ id: year, name: year });
    }
    return years;
  }

  getMappedShift(shift: string): string {
    switch (shift) {
      case 'Shift A':
        return 'A';
      case 'Shift B':
        return 'B';
      case 'Shift C':
        return 'C';
      case 'Shift G':
        return 'G';
      default:
        return '';
    }
  }

  async chartBase64(chartContainer: any) {
    const chartCont = chartContainer?.nativeElement;
    const canvas = await html2canvas(chartCont);
    const imageUrl = canvas.toDataURL('image/png');
    const base64Data = imageUrl.split(';base64,').pop() || '';
    const buffer = this.base64ToArrayBuffer(base64Data);
    return buffer;
  }

  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes.buffer;
  }

  arrayBufferToBase64(buffer: any) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  downloadExcel(base64Data: string, filename: string): void {
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = this.base64ToBlob(base64Data, contentType);
    saveAs(blob, filename);
  }

  getDropdownData(
    fieldName: string,
    selectedId: any,
    compareKey: string = 'id'
  ) {
    const data = localStorage.getItem(fieldName);
    if (data?.length) {
      const foundData = JSON.parse(data).find(
        (item: any) => item[compareKey] == selectedId
      );
      return foundData;
    }
    return {};
  }

  getCommonChartFilters(
    filter: any,
    lineId: any,
    plantId: any,
    factoryId: any
  ) {
    const lineData = this.getDropdownData('lineData', Number(lineId));
    const factoryData = this.getDropdownData('factoryData', Number(factoryId));
    const plantData = this.getDropdownData('plantData', Number(plantId));
    if (lineId) {
      set(filter, 'LineId', Number(lineId));
      set(filter, 'LineExcelName', lineData?.lineName);
    }

    if (plantId) {
      set(filter, 'plantExcelName', plantData?.name);
      set(filter, 'plantId', Number(plantId));
    }

    if (factoryId) {
      set(filter, 'factoryId', Number(factoryId));
      set(filter, 'factoryExcelName', factoryData?.factoryName);
    }

    return filter || {};
  }
}
