<p-dropdown
  #dropdown
  [options]="sortOptions"
  [(ngModel)]="selectedSortOption"
  (onChange)="onSortOptionChange($event)"
  (onHide)="resetFilter()"
  [placeholder]="placeholder"
  [optionLabel]="labelName"
  [class]="dropdownClass + ' filter-dropdown'"
  [filter]="isFilter"
  [filterBy]="labelName"
  [showClear]="isShowClear"
  [disabled]="isDisabled"
>
</p-dropdown>
